import React from 'react';
import styled from 'styled-components';
import theme from '../../themes/default';

const SmallScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${theme.colors.background1};
  color: ${theme.colors.text1};
  font-family: ${theme.fonts.main};
  text-align: center;
  padding: 20px;

  @media ${theme.breakpoints.md} {
    font-size: 14px;
  }

  @media ${theme.breakpoints.sm} {
    font-size: 12px;
  }
`;

const SmallScreen = () => {
  return (
    <SmallScreenContainer>
      <div>
        <h2>Screen size is too small for the best view</h2>
        <p>Switch to desktop mode or go to a laptop screen for an optimal experience.</p>
      </div>
    </SmallScreenContainer>
  );
};

export default SmallScreen;
export const Bio = {
  name: "Krupesh Vachhani",
  roles: [
    "Software Engineer",
    "Full Stack Developer",
    "App Developer",
    "Programmer",
  ],
  // description:
  //   "Driven, ambitious individual thriving on challenges. Pursuing excellence with boundless enthusiasm and continuous growth. Poised for remarkable success.",
  github: "https://github.com/KrupeshVachhani/",
  // resume:
  // "https://docs.google.com/document/d/1H7jnMs0aPwkVlN9h-RnpZ-kUy4J9cUnO-Lra0PLsAH0/edit?usp=sharing",
  // "src/KrupeshResume-16-3-24.pdf",
  linkedin: "https://www.linkedin.com/in/krupesh-vachhani",
  twitter: "https://x.com/KrupeshVachhani?t=lLkhKtCGOpiqFUuo4ir14Q&s=09",
  // insta: "https://www.linkedin.com/in/krupesh-vachhani",
  insta: "https://www.instagram.com/krupesh_vachhani/",
  MicrosoftLearn:
    "https://learn.microsoft.com/en-us/users/krupeshvachhani/transcript/vjjx5s4qr53e3yo",
  youtube: "https://youtube.com/@mr.penguin_coder?si=T86-Rp93hcrChaHk",
  cal: "https://cal.com/krupeshvachhani",
  whatsApp: "https://wa.me/9426628199",
};

export const skills = [
  {
    title: "Languages",
    skills: [
      {
        name: "JavaScript",
        image: "/skills/javascript.png",
        type: "advanced",
      },
      {
        name: "TypeScript",
        image: "/skills/typescript.png",
        type: "advanced",
      },
      {
        name: "Java",
        image: "/skills/java.png",
      },
    ],
  },

  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image: "/skills/react.png",
        type: "advanced",
      },
      {
        name: "Redux Toolkit",
        image: "/skills/redux.png",
        type: "advanced",
      },

      {
        name: "Next Js",
        image: "/skills/nextjs.png",
        type: "advanced",
      },

      {
        name: "Bootstrap",
        image: "/skills/bootstrap.png",
        type: "advanced",
      },
      {
        name: "Tailwind CSS",
        image: "/skills/tailwindcss.png",
        type: "advanced",
      },
      {
        name: "Framer Motion",
        image: "/skills/framer.png",
      },

      {
        name: "Material UI",
        image: "/skills/materialui.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "/skills/nodejs.png",
        type: "advanced",
      },
      {
        name: "Express Js",
        image: "/skills/expressjs.png",
        type: "advanced",
      },
      {
        name: "Graph Ql",
        image: "/skills/graphql.png",
      },
      {
        name: "Redis",
        image: "/skills/redis.png",
      },
      // {
      //   name: "MySQL",
      //   image: "/skills/mysql.png",
      // },
      {
        name: "Postgresql",
        image: "/skills/postgresql.png",
      },
      {
        name: "MongoDB",
        image: "/skills/mongodb.png",
        type: "advanced",
      },
      {
        name: "Postman",
        image: "/skills/postman.png",
        type: "advanced",
      },
      {
        name: "Firebase",
        image: "/skills/firebase.png",
      },
    ],
  },
  {
    title: "Mobile App Development",
    skills: [
      {
        name: "Jetpack Compose",
        image: "/skills/compose.png",
      },
      {
        name: "React Native",
        image: "/skills/react.png",
      },
      {
        name: "Flutter",
        image: "/skills/flutter.png",
        type: "advanced",
      },
    ],
  },

  {
    title: "IDEs",
    skills: [
      {
        name: "Neo Vim",
        image: "/skills/neovim.png",
      },
      {
        name: "Android Studio",
        image: "/skills/androidstudio.png",
      },
      {
        name: "VS Code",
        image: "/skills/visualstudiocode.png",
      },
    ],
  },
  {
    title: "Cyber Security",
    skills: [
      {
        name: "basic Kali Linux",
        image: "/skills/kali.png",
      },
      {
        name: "Burp Suite",
        image: "/skills/burp.png",
      },
    ],
  },
  {
    title: "AI used",
    skills: [
      {
        name: "Gemini",
        image: "/skills/gemini.png",
        type: "advanced",
      },
      {
        name: "chatgpt",
        image: "/skills/chatgpt.png",
        type: "advanced",
      },
      {
        name: "claude",
        image: "/skills/claude.png",
        type: "advanced",
      },
      {
        name: "Meta Lama",
        image: "/skills/meta.png",
        type: "advanced",
      },
    ],
  },
  {
    title: "Devops",
    skills: [
      {
        name: "GitHub",
        image: "/skills/github.png",
        type: "advanced",
      },
      {
        name: "Docker",
        image: "/skills/docker.png",
      },
      {
        name: "Netlify",
        image: "/skills/netlify.png",
        type: "advanced",
      },
      {
        name: "AWS",
        image: "/skills/aws.png",
        type: "advanced",
      },
      {
        name: "Heroku",
        image: "/skills/heroku.png",
      },
      {
        name: "Vercel",
        image: "/skills/vercel.png",
      },
      {
        name: "Hostinger",
        image: "/skills/hostinger.png",
        type: "advanced",
      },
    ],
  },
  {
    title: "UI/UX Designing",
    skills: [
      {
        name: "Figma",
        image: "/skills/figma.png",
      },
      {
        name:"Microsoft Designer",
        image:"/skills/MicrosoftDesigner.png"
      }
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "/certifications/techara.png",
    role: "Software Development Intern",
    company: "Techara Infoway Pvt. Ltd.",
    date: "Aug 2023 - Feb 2024",
    desc: "Developed websites with React.js, Node.js, MongoDB, Express. Explored Flutter to broaden skills and enhance development capabilities.",
    skills: ["React Js", "Node Js", "MongoDB", "Express Js", "GitHub"],
    // doc: "https://media.licdn.com/dms/image/D4D2DAQFlp60ZqHuaFQ/profile-treasury-image-shrink_1280_1280/0/1691180828512?e=1692381600&v=beta&t=mM5Y_NE5EPlQhez5FAN6NLVSKcO_Ojt_9Gq3mnFGkAQ",
  },
];

export const education = [
  {
    id: 1,
    school: "Marwadi University, Rajkot, Gujarat",
    date: "Sep 2021 - Apr 2025",
    grade: "9.11 CGPA",
    // desc: "I am currently pursuing a Bachelor's degree in Computer Science and Engineering at Kalinga Institute of Industrial Technology, Bhubaneswar. I have completed 4 semesters and have a CGPA of 8.71. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I am also a member of the Google Developers Student Club (GDSC) at KIIT, where I am learning and working on exciting projects with a team of talented developers.",
    degree: "Bachelor of Technology - BTech, Computer Engineering",
  },
  {
    id: 2,
    school: "Aum Vidya Mandir(IFFCO) , Gandhidham",
    date: "Apr 2020 - Apr 2021",
    grade: "74%",
    // desc: "Embarking on the scientific odyssey of 12th grade, delving into the intricate world of mathematics, exploring concepts, and embracing challenges in school.",
    degree: "GSEB(XII), Science with Computer",
  },
  {
    id: 3,
    school: "Jainacharya Ajramar Shri Sarswati Madhyamik Vidhyalay, Gandhidham",
    date: "Apr 2018 - Apr 2019",
    grade: "85%",
    // desc: "An enlightening journey in my 10th class: budding friendships, academic challenges, joyful moments, and self-discovery amid diverse experiences..",
    degree: "GSEB(X)",
  },
];

export const projects = [
  {
    id: 9,
    title: "Book Ordering Backend",
    date: "Jul 2023",
    description:
      "I specialize in creating robust backend solutions, demonstrated in my latest project where I seamlessly integrated Google Authentication using PassportJS and Razorpay payment gateway for developer mode. My expertise lies in Node.js and Express.js, complemented by MongoDB Atlas and Mongoose for efficient database management. I implemented all CRUD operations and designed an intuitive admin section for privileged users. Adhering to best practices, I ensured security with .env file integration and followed the MVC architecture, optimizing project structure and enhancing scalability. With a keen eye for detail, I deliver high-quality, secure, and performant backend systems.",
    image: "/Projects/bookbackend.png",
    tags: [
      "20% Code written by AI",
      "Google Auth",
      "Razorpay Integration",
      "Docker",
      "MVC Architecture",
      // "AWS",
      // "DuckDNS",
      // "Eslint",
      // "CI/CD",
      // "React Js",
      "MongoDb",
      "MongoDB Atlas",
      "Mongoose",
      "Node Js",
      "Express Js",
      // "Redux",
    ],
    category: "Backend Development",
    github: "https://github.com/KrupeshVachhani/Book-Ordering-Backend",
    DemoVideoLink: "https://youtu.be/IUlf-plIHQE",
  },
  {
    id: 9,
    title: "Telegram Bot - NodeJS",
    date: "Mar 2024",
    description:
      "Welcome to our advanced bot! Here, you can execute your JavaScript code, fetch JSON data from any URL, and even access Google's Gemini AI for assistance. With our integrated Gemini API and Gemini Pro model, you can expect top-notch support and guidance. Whether you're debugging code, gathering data, or seeking AI-powered insights, our bot has got you covered. Simply input your commands, and let the magic unfold!",
    image: "/Projects/telegrambot.jpg",
    tags: [
      "10% Code written by AI",
      "Telegram Bot",
      "NodeJS",
      "JavaScript",
      "Gemini API",
      "Gemini Pro",
    ],
    category: "Backend Development",
    github: "https://github.com/KrupeshVachhani/TelegramBot-backend",
    DemoVideoLink: "https://youtu.be/a1lKRAffjBs",
  },
  {
    id: 9,
    title: "Code Pen Clone",
    date: "Feb 2024",
    description:
      "This CodePen clone offers a streamlined method for writing HTML, CSS, and JavaScript code. Users can seamlessly merge their code and view the output directly in the browser. The platform provides templates for quick start-ups and offers live previews of the code. Additionally, users can explore and replicate designs uploaded by others, leveraging live search functionality to discover projects of interest.",
    image: "/Projects/codepenclone.png",
    tags: [
      "10% Code written by AI",
      "Telegram Bot",
      "NodeJS",
      "JavaScript",
      "Gemini API",
      "Gemini Pro",
    ],
    category: "web app",
    github: "https://github.com/KrupeshVachhani/CodePenClone",
    DemoVideoLink: "https://youtu.be/945i_5Lc9ys",
    DeployedLink: "https://codepen-clone-ff3f4.web.app/",
  },
  {
    id: 9,
    title: "Group Chat App",
    date: "Dec 2023",
    description:
      "Developed a Next.js-based chat app leveraging Redis, RedisInsight, and Socket.io. Enables multiple chat connections, facilitating real-time communication with timestamps for seamless interactions and enhanced user experience.",
    image: "/Projects/chatapp.jpg",
    tags: ["Telegram Bot", "NodeJS", "JavaScript", "Gemini API", "Gemini Pro"],
    category: "web app",
    github: "https://github.com/KrupeshVachhani/chat-app",
    DemoVideoLink: "https://youtu.be/pdMGH_VBHg0",
  },
  {
    id: 0,
    title: "Food Adda - MERN Stack",
    date: "May 2023",
    description:
      "Our project, This is an web application developed using the MERN (MongoDB, Express.js, React, Node.js) stack technology. This comprehensive system seamlessly integrates powerful technologies and features, providing a robust platform for both customers and administrators. The project focuses on enhancing user interaction through React Motion for smooth animations, Chart.js for detailed visualizations, and an automated email system to keep users informed about their orders.",
    image: "/Projects/foodadda.png",
    tags: [
      "30% Code written by AI",
      "React Js",
      "MongoDb",
      "Node Js",
      "Express Js",
      "NodeMailer",
      "Motion.js",
      "Chart.js",
    ],
    category: "web app",
    github: "https://github.com/KrupeshVachhani/FoodAdda",
    DemoVideoLink: "https://youtu.be/8_U81HTZNJg",
  },
  {
    id: 1,
    title: "Fun Text",
    date: "Oct 2022",
    description:
      "This project features a user input section with various functionalities: displaying uppercase, lowercase, word count, reading time etc. Additionally, it offers options to remove spaces copy text etc. Developed using React technology",
    image: "/Projects/funtext.png",
    tags: ["React Js", "JavaScript", "React Hooks"],
    category: "web app",
    github: "https://github.com/KrupeshVachhani/Text-Fun",
    DemoVideoLink: "https://youtu.be/tU2FTxQzWs4",
    DeployedLink: "https://krupeshvachhani.github.io/Text-Fun/",
  },
  // {
  //   id: 7,
  //   title: "ToDo App",
  //   date: "Nov 2023",
  //   description:
  //     "TaskMaster is a user-friendly and intuitive todo app designed to help you manage your tasks efficiently. Whether you're organizing your daily chores, planning a project, or creating a shopping list, TaskMaster simplifies task management with its clean interface and versatile features.",
  //   image:
  //     "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
  //   tags: ["React-Native", "Javascript", "Android Studio"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Quiz-Earn",
  //   DemoVideoLink: "https://github.com/rishavchanda/Quiz-Earn",
  // },
  // {
  //   id: 7,
  //   title: "Ai Chat/Image bot App-Gemini",
  //   date: "Nov 2023",
  //   description:
  //     "TaskMaster is a user-friendly and intuitive todo app designed to help you manage your tasks efficiently. Whether you're organizing your daily chores, planning a project, or creating a shopping list, TaskMaster simplifies task management with its clean interface and versatile features.",
  //   image:
  //     "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
  //   tags: ["React-Native", "Javascript", "Android Studio"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Quiz-Earn",
  //   DemoVideoLink: "https://github.com/rishavchanda/Quiz-Earn",
  // },
  {
    id: 7,
    title: "Scan Barcode And Pay the bills App",
    date: "dec 2023",
    description:
      "Our Flutter app, connected with Firebase, provides login, signup, and real-time email verification. Users can scan barcodes to create item lists and proceed to a swift payment process, ensuring a seamless shopping experience. With no waiting period, users can effortlessly complete their purchases, making shopping convenient and efficient.",
    image: "/Projects/y-scanNpay.jpg",
    tags: [
      "40% Code written by AI",
      "Flutter",
      "Firebase",
      "email Verification",
      "Local Storage",
    ],
    category: "android app",
    // github: "https://github.com/rishavchanda/Quiz-Earn",
    DemoVideoLink: "https://youtu.be/37mNZnm4Lx8",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];

export const certifications = [
  {
    id: 0,
    img: "/certifications/aws.png",
    title: "AWS Cloud Foundation Certification",
    OrganizationName: "Amazon Web Services (AWS)",
    date: "Issued Nov 2023",
    skills: [
      "AWS",
      "EC2",
      "Nginx",
      "Load Balancing",
      "Serverless Architecture",
      "AWS IAM",
      " Lambda",
      "S3",
      "Route 53",
      "Cloud 9",
      "Amazon VPC",
    ],
  },
  {
    id: 0,
    img: "/certifications/oracleceri.png",
    title: "Oracle Certification MySQL/PlSQL",
    OrganizationName: "Oracle",
    date: "Issued Oct 2022",
    skills: ["Mysql", "PlSQL", "Database", "SQL", "Database Management"],
  },

  {
    id: 0,
    img: "/certifications/OperatingSystem.png",
    title: "NDG Linux Essentials",
    OrganizationName: "Cisco Networking Academy",
    date: "Issued Mar 2023",
    skills: ["Linux"],
  },
  {
    id: 0,
    img: "/certifications/EthicalHacking.png",
    title: "Ethical Hacking Training",
    OrganizationName: "Internshala ",
    date: "Issued Oct 2022",
    skills: ["Burp Suite", "SQL Injection", "XSS", "CSRF", "OWASP Top 10"],
  },
  {
    id: 0,
    img: "/certifications/CCNAv7.png",
    title: "CCNAv7: Introduction to Networks",
    OrganizationName: "Cisco Networking Academy",
    date: "Issued Feb 2023",
    skills: ["Networking", "Protocols"],
  },
  {
    id: 0,
    img: "/certifications/CCNAv72.png",
    title: "CCNAv7: Switching, Routing, and Wireless Essentials",
    OrganizationName: "Cisco Networking Academy",
    date: "Issued Mar 2023",
    skills: ["Routing", "Switching", "Wireless Networking", "Protocols"],
  },
  {
    id: 0,
    img: "/certifications/BoardingPass_MyNameOnFutureMission.png",
    title: "Future Mars Mission",
    OrganizationName: "NASA",
    date: "Issued Sept 2023",
    skills: ["NASA Mars Mission"],
  },
];
